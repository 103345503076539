.card-title-bold {
	font-size: 20px;
	font-weight: bold;
	justify-content: center !important;
}
.btn-red {
	background:-webkit-gradient(linear, left top, left bottom, from(#f44336), to(#f92212));
	background:-o-linear-gradient(top, #f44336 0%, #f92212 100%);
	background:linear-gradient(180deg, #f44336 0%, #f92212 100%);
	color:#fff;
}
.btn-red:hover {
	background:#cf0e00;
	color: #cbff00;
}
.btn-half {
	width: 50% !important;
	float: left;
}
.btn-half-r {
    border-bottom-right-radius: 0px !important;
}
.btn-half-l {
    border-bottom-left-radius: 0px !important;
}
.btn-float {
	float: left;
	margin-right: 20px;
}
.card-border {
    background-repeat: no-repeat;
    border: 1px solid #fff;
    border-radius: 15px;
    margin: 20px 20px 50px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}
.card-border button {
	min-width: 150px;
}
.p-strong {
	font-size: 18px;
	font-weight: 700;
}
.c-p {
	padding: 0 15px;
}
.s-header {
	margin-bottom: 0 !important;
}
.s-main {
}
.s-main .divider {
	margin-top: 60px;
}
.s-main .c-header {
	margin-top: 50px;
}
.s-main .header-up {
	margin-top: 10px;
}
.c-header.c-title {
    justify-content: center;
}

.img {
	max-width: 100%;
}

.info-steps {
	margin: 50px 0 100px;
	background-image: url(../pics/info-steps.png);
    background-size: cover;
    width: 100%;
    min-height: 450px;
    display: flex;
    justify-content: flex-end;
}


.info-step {
    width: 33.33%;
}

.info-step-1 {
	order: 1;
}

.info-step-2 {
	order: 2;
    margin-top: 56px;
}

.info-step-3 {
	order: 3;
    margin-top: 122px;
}

.info-step .btn {
	margin-left: 180px;
	min-width: 180px;
	max-width: 200px;
}

.socials {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.socials li {
    padding: 0 3px;
}

.socials a {
    display: block;
    border-radius: 50%;
}

.socials a:hover {
    -webkit-box-shadow: 0px 4px 40px #66fdd0;
    box-shadow: 0px 4px 40px #66fdd0;
}

.socials img {
    display: block;
    border-radius: 50%;
    width: 25px;
    height: 25px;
}

@media (min-width: 1024px) {
.info-steps p {
	display: none;
}
.lp-list {
	margin-top: 40px;
}
}

@media (min-width: 1310px) {
.c-header .h3 {
    margin-bottom: 20px;
}
.socials img {
    width: 30px;
    height: 30px;
}
}
@media (max-width: 420px) {
.s-main .c-header .h3 {
	font-size: 40px;
}
.info-step {
	width: 100%;
}
.info-steps {
	display: block;
}
}
@media (max-width: 1024px) {
.info-step {
	width: 100%;
	margin-top: 50px;
}
.info-step .btn {
	margin: 0 auto;
}
.info-steps {
	background: none;
	display: block;
}
.info-steps p {
	display: block;
	text-align: center;
}
}
